<template>
  <v-app class="home">
    <v-app-bar app color="primary" dark prominent src="dictionary.jpg">
      <template v-slot:img="{ props }">
        <v-img
          v-bind="props"
          gradient="to top right, rgba(19,84,122,.5), rgba(128,208,199,.8)"
        ></v-img>
      </template>
      <v-container>
        <v-icon v-on:click="$router.go(-1)">
          mdi-arrow-left
        </v-icon>
        <v-btn class="bewaarBtn" outlined @click="okClick">
          Bewaar
        </v-btn>
      </v-container>
    </v-app-bar>
    <v-main>
      <v-form class="ml-5 mr-5">
        <br />
        <v-list-item-content>
          <v-list-item-title
            class="title"
            v-text="item.woord"
          ></v-list-item-title>

          <v-list-item-subtitle
            class="text--primary text-wrap"
            v-text="item.betekenis"
          ></v-list-item-subtitle>
        </v-list-item-content>

        <div v-if="this.item.knipsel != null">
          <img
            @click="imageClick"
            class="preview"
            height="268"
            width="330"
            :src="this.item.knipsel"
          />
          <input
            type="file"
            ref="input1"
            style="display: none"
            @change="previewImage"
            accept="image/*"
          />
        </div>
        <input
          type="file"
          ref="input1"
          style="display: none"
          @change="previewImage"
          accept="image/*"
        />
        <v-card-actions>
          <v-btn left @click="click1">Knipsel</v-btn>
        </v-card-actions>
        <v-card-actions>
          <v-btn left @click="deleteButton">Delete</v-btn>
        </v-card-actions>
      </v-form>
    </v-main>
  </v-app>
</template>

<script>
import OnbekendeWoordenService from "../services/OnbekendeWoordenService";
import GebruikelijkVue from "./Gebruikelijk.vue";
import storageRef, { firebaseStorage } from "../firebase";
import * as firebase from "firebase";

function basename(str) {
  return str.substr(0, str.lastIndexOf("."));
}

export default {
  data() {
    return {
      item: {
        id: "",
        woord: "",
        betekenis: "",
        knipsel: "",
      },
      imageData: null,
      submitted: false,
    };
  },
  created() {
    console.log(this.item);
    this.item = this.$route.params.item;
  },
  methods: {
    click1() {
      this.$refs.input1.click();
    },
    deleteButton() {
      console.log("delete " + this.item.ID);
      OnbekendeWoordenService.delete(this.item.ID);
      this.$router.go(-1);
    },
    imageClick() {
      console.log(this.item.knipsel);
      this.$router.push({
        name: "Image",
        params: { image: this.item.knipsel },
      });
    },

    previewImage(event) {
      this.uploadValue = 0;
      this.item.knipsel = null;
      this.imageData = event.target.files[0];

      this.onUpload();
    },

    async onUpload() {
      this.item.knipsel = null;
      var ref = "knipsels/" + basename(this.imageData.name);
      var storageRef = firebaseStorage.ref();
      var uploadTask = storageRef.child(ref).put(this.imageData);
      uploadTask.on(
        firebase.storage.TaskEvent.STATE_CHANGED,
        (snapshot) => {
          // Get task progress, including the number of bytes uploaded and the total number of bytes to be uploaded
          var progress =
            (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
          //console.log("Upload is " + progress + "% done");
          switch (snapshot.state) {
            case firebase.storage.TaskState.PAUSED:
              //console.log("Upload is paused");
              break;
            case firebase.storage.TaskState.RUNNING:
              //console.log("Upload is running");
              break;
          }
        },
        (error) => {
          switch (error.code) {
            case "storage/unauthorized":
              // User doesn't have permission to access the object
              break;
            case "storage/canceled":
              // User canceled the upload
              break;
            case "storage/unknown":
              // Unknown error occurred, inspect error.serverResponse
              break;
          }
        },
        () => {
          // Upload completed successfully, now we can get the download URL
          uploadTask.snapshot.ref.getDownloadURL().then((downloadURL) => {
            console.log("URL: ", downloadURL);
            this.item.knipsel = downloadURL;
          });
        }
      );
    },
    okClick() {
      console.log("OK click for ID " + this.item.ID);
      var data = {
        ID: this.item.ID,
        Woord: this.item.woord,
        Betekenis: this.item.betekenis,
        Gelezen: this.item.gelezen,
      };

      if (this.item.knipsel) {
        data["Knipsel"] = this.item.knipsel;
      }
      OnbekendeWoordenService.update(data);
      this.$router.go(-1);
    },
    cancelClick() {
      this.$router.go(-1);
    },
    knipselClick() {
      console.log("knipsel click");
      this.$refs.input1.click();
    },
  },
};
</script>

<style>
.submit-form {
  max-width: 300px;
  margin: auto;
}
.btn {
  text-transform: none !important;
}
.bewaarBtn {
  color: white;
  float: right;
}
.no-uppercase {
  font-size: 5px;
  text-transform: none;
}
.text-field {
  width: 400px;
}
</style>
