<template>
  <v-app class="home">
    <v-app-bar app color="primary" dark prominent src="dictionary.jpg">
      <template v-slot:img="{ props }">
        <v-img
          v-bind="props"
          gradient="to top right, rgba(19,84,122,.5), rgba(128,208,199,.8)"
        ></v-img>
      </template>
      <v-container>
        <v-icon v-on:click="$router.go(-1)">
          mdi-arrow-left
        </v-icon>
        <v-btn class="bewaarBtn" outlined @click="addWoord">
          Bewaar
        </v-btn>
      </v-container>
    </v-app-bar>
    <v-main>
      <v-form class="mt-10 ml-5 mr-5">
        <br />
        <v-text-field
          label="Woord"
          v-model="item.woord"
          required
        ></v-text-field>

        <v-textarea
          label="Betekenis"
          v-model="item.betekenis"
          required
          rows="3"
        ></v-textarea>

        <br />

        <v-btn @click="click1">Knipsel toevoegen</v-btn>
        <input
          type="file"
          ref="input1"
          style="display: none"
          @change="previewImage"
          accept="image/*"
        />
        <div v-if="imageData != null">
          <img
            class="preview"
            height="268"
            width="356"
            :src="this.item.knipsel"
          />
        </div>
      </v-form>
    </v-main>
  </v-app>
</template>

<script>
import OnbekendeWoordenService from "../services/OnbekendeWoordenService";
import GebruikelijkVue from "./Gebruikelijk.vue";
import storageRef, { firebaseStorage } from "../firebase";
import * as firebase from "firebase";

function basename(str) {
  return str.substr(0, str.lastIndexOf("."));
}

export default {
  name: "add-tutorial",
  data() {
    return {
      item: {
        woord: "",
        betekenis: "",
        knipsel: "",
      },
      imageData: null,
      submitted: false,
    };
  },
  methods: {
    click1() {
      this.$refs.input1.click();
    },

    previewImage(event) {
      this.uploadValue = 0;
      this.item.knipsel = null;
      this.imageData = event.target.files[0];

      this.onUpload();
    },

    async onUpload() {
      this.item.knipsel = null;
      var ref = "knipsels/" + basename(this.imageData.name);
      var storageRef = firebaseStorage.ref();
      var uploadTask = storageRef.child(ref).put(this.imageData);
      uploadTask.on(
        firebase.storage.TaskEvent.STATE_CHANGED,
        (snapshot) => {
          // Get task progress, including the number of bytes uploaded and the total number of bytes to be uploaded
          var progress =
            (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
          //console.log("Upload is " + progress + "% done");
          switch (snapshot.state) {
            case firebase.storage.TaskState.PAUSED:
              //console.log("Upload is paused");
              break;
            case firebase.storage.TaskState.RUNNING:
              //console.log("Upload is running");
              break;
          }
        },
        (error) => {
          switch (error.code) {
            case "storage/unauthorized":
              // User doesn't have permission to access the object
              break;
            case "storage/canceled":
              // User canceled the upload
              break;
            case "storage/unknown":
              // Unknown error occurred, inspect error.serverResponse
              break;
          }
        },
        () => {
          // Upload completed successfully, now we can get the download URL
          uploadTask.snapshot.ref.getDownloadURL().then((downloadURL) => {
            console.log("URL: ", downloadURL);
            this.item.knipsel = downloadURL;
          });
        }
      );
    },
    addWoord() {
      var data = {
        Woord: this.item.woord.toLowerCase(),
        Betekenis: this.item.betekenis,
        Gelezen: 1,
        Knipsel: this.item.knipsel,
      };

      OnbekendeWoordenService.create(data);
      this.$router.push("/");
    },
  },
};
</script>

<style>
.submit-form {
  max-width: 300px;
  margin: auto;
}
.btn {
  text-transform: none !important;
}
.no-uppercase {
  font-size: 5px;
  text-transform: none;
}
.text-field {
  width: 400px;
}
</style>
