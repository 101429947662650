<template>
  <v-app class="home">
    <v-app-bar app color="primary" dark prominent src="dictionary.jpg">
      <template v-slot:img="{ props }">
        <v-img
          v-bind="props"
          gradient="to top right, rgba(19,84,122,.5), rgba(128,208,199,.8)"
        ></v-img>
      </template>
      <v-container>
        <v-row>
          <v-col cols="10" sm="6">
            <v-text-field
              clearable
              rounded
              v-model="filterTerm"
              outlined
              prepend-inner-icon="mdi-magnify"
            ></v-text-field>
          </v-col>
          <v-col>
            <v-icon v-on:click="newItem()">
              mdi-plus
            </v-icon>
          </v-col>
        </v-row>
        <v-row>
          <v-btn
            class="no-uppercase"
            style="margin-left:15px; margin-top:-15px;"
            v-on:click="buttonGebruikelijk"
            color="green"
            rounded
            small
            elevation="22"
          >
            <span style="font-size: 14px">xGebruikelijk</span>
          </v-btn>
          <v-btn
            class="no-uppercase"
            style="margin-left:5px; margin-top:-15px;"
            v-on:click="buttonOngebruikelijk"
            color="red"
            rounded
            small
            elevation="22"
          >
            <span style="font-size: 14px">Ongebruikelijk</span>
          </v-btn>
          <v-btn
            class="no-uppercase"
            style="margin-left:5px; margin-top:-15px;"
            v-on:click="buttonAllen"
            color="orange"
            rounded
            small
            elevation="22"
          >
            <span style="font-size: 14px">Allen</span>
          </v-btn>
        </v-row>
      </v-container>
    </v-app-bar>
    <v-main>
      <v-list two-line>
        <v-list-item-group v-model="selected" multiple>
          <template v-for="(item, index) in orderBy(filteredItems, 'woord')">
            <v-list-item :key="item.woord">
              <template v-slot:default="{ active }">
                <v-list-item-avatar
                  class="pt-0 pb-0"
                  tile
                  size="50"
                  @click.native="knipselClick(item)"
                >
                  <!-- <v-img :src="item.knipsel"></v-img> -->
                </v-list-item-avatar>
                <v-list-item-content @click="itemClick(item)">
                  <v-list-item-title
                    class="title"
                    v-text="item.woord"
                  ></v-list-item-title>

                  <v-list-item-subtitle
                    class="text--primary text-wrap"
                    v-text="item.betekenis"
                  ></v-list-item-subtitle>
                </v-list-item-content>

                <v-list-item-action>
                  <v-list-item-action-text
                    v-text="parseInt(item.gelezen) < 99 ? item.gelezen : ''"
                  ></v-list-item-action-text>

                  <v-icon
                    v-if="parseInt(item.gelezen) < 99"
                    color="red"
                    v-on:click="incrementGelezen(index + 1, item)"
                  >
                    mdi-star
                  </v-icon>
                  <v-icon
                    v-else
                    color="green"
                    v-on:click="incrementGelezen(index + 1, item)"
                  >
                    mdi-star
                  </v-icon>
                </v-list-item-action>
              </template>
            </v-list-item>

            <v-divider v-if="index < items.length - 1" :key="index"></v-divider>
          </template>
        </v-list-item-group>
      </v-list>
    </v-main>
  </v-app>
</template>

<script>
import OnbekendeWoordenService from "../services/OnbekendeWoordenService.js";
import Vue2Filters from "vue2-filters";

var onbekendeWoorden = [];

export default {
  mixins: [Vue2Filters.mixin],
  data: () => ({
    filterTerm: "",
    filterGebruikelijk: false,
    filterOngebruikelijk: false,
    filterAllen: true,
    selected: [2],
    items: onbekendeWoorden,
    drawer: null,
  }),
  computed: {
    filteredItems() {
      var searchString =
        this.filterTerm !== null && this.filterTerm.length > 0
          ? this.filterTerm.toLowerCase()
          : "";
      return this.items.filter((item) => {
        return (
          item.woord.startsWith(searchString) &&
          (this.filterGebruikelijk ? parseInt(item.gelezen) >= 99 : true) &&
          (this.filterOngebruikelijk ? parseInt(item.gelezen) < 99 : true)
        );
      });
    },
    colorClass(item) {
      let color = item.gelezen < 99 ? "red" : "green";
      return "color: " + color;
    },
  },
  methods: {
    newItem() {
      this.$router.push("/new");
    },
    incrementGelezen(ID, item) {
      console.log("incrementGelezen " + item.ID + " " + item.woord);
      item.gelezen = item.gelezen + 1;
      var incrementedGelezen = +item.gelezen;
      const data = {
        ID: item.ID,
        Woord: item.woord,
        Gelezen: incrementedGelezen,
      };
      OnbekendeWoordenService.update(data)
        .then(() => {
          console.log(item.woord + ": " + incrementedGelezen);
        })
        .catch((e) => {
          console.log(e);
        });
    },
    knipselClick(item) {
      console.log("Item " + item.woord + " clicked");
      this.$router.push({
        name: "Image",
        params: { image: item.knipsel },
      });
    },
    itemClick(item) {
      this.$router.push({ name: "Edit", params: { item: item } });
    },
    buttonGebruikelijk() {
      this.filterGebruikelijk = true;
      this.filterOngebruikelijk = false;
      this.filterAllen = false;
    },

    buttonAllen() {
      this.filterAllen = true;
      this.filterGebruikelijk = false;
      this.filterOngebruikelijk = false;
    },

    buttonOngebruikelijk() {
      this.filterOngebruikelijk = true;
      this.filterGebruikelijk = false;
      this.filterAllen = false;
    },
    onDataChange(items) {
      onbekendeWoorden.length = 0;
      items.forEach((item) => {
        let key = item.key;
        let value = item.val();
        onbekendeWoorden.push({
          ID: value.ID,
          woord: value.Woord,
          betekenis: value.Betekenis,
          gelezen: value.Gelezen,
          knipsel: value.Knipsel,
        });
      });
      OnbekendeWoordenService.setOnbekendeWoordenCount(onbekendeWoorden.length);
      console.log("Gebruikelijk.onDataChange " + onbekendeWoorden.length);
    },
  },
  mounted() {
    OnbekendeWoordenService.getAll().on("value", this.onDataChange);
    this.filterTerm = OnbekendeWoordenService.getFilterTerm();
    this.filterGebruikelijk = OnbekendeWoordenService.getFilterGebruikelijk();
    this.filterOngebruikelijk = OnbekendeWoordenService.getFilterOngebruikelijk();
    this.filterAllen = OnbekendeWoordenService.getFilterAllen();
    console.log(
      "Gebruikelijk.mounted " + OnbekendeWoordenService.getAll().length
    );
  },
  beforeDestroy() {
    OnbekendeWoordenService.setFilterGebruikelijk(this.filterGebruikelijk);
    OnbekendeWoordenService.setFilterOngebruikelijk(this.filterOngebruikelijk);
    OnbekendeWoordenService.setFilterAllen(this.filterAllen);
    OnbekendeWoordenService.setFilterTerm(this.filterTerm);
    OnbekendeWoordenService.getAll().off("value", this.onDataChange);
  },
};
</script>

<style scoped>
.btn {
  text-transform: none !important;
}
.no-uppercase {
  font-size: 5px;
  text-transform: none;
}
.text-field {
  width: 400px;
}
::v-deep .v-list-item {
  margin-top: 0px;
  margin-bottom: 0px;
  padding-bottom: 0px;
  padding: 12px;
  padding-top: 0px;
}
.v-list__tile {
  padding: 0;
}

.v-application--is-ltr .v-list-item__avatar:first-child {
  margin-right: 12px;
}
</style>
